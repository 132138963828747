
import React, { useEffect, useState } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselCaption, CarouselIndicators } from "reactstrap";;

const captions = [
    {
        heading: 'Welcome to n4nairmatrimony',
        altText: 'An exclusive matrimonial portal for Nair community',
        caption: 'We will plan the wedding of your dreams',
        signin: 'https://dashboard.n4nairmatrimony.com/auth-login',
        register: 'https://dashboard.n4nairmatrimony.com/auth-register',
        playstore: 'https://play.google.com/store/apps/details?id=com.mounts.n4nairmatrimony'
    }

];

function BasicCarousel() {
    const [items, setItems] = useState([])
    useEffect(() => {
        getdata()
    }, [])
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const getdata = async () => {
        const sendData2 = {
            OrgId: Number(53),
            WebHeader70Fixed: 'MyProfileFile',
            UserId: Number(594)
        }

        await fetch('https://mountsbniapi.azurewebsites.net/api/CloudData/ProfileImageAll', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendData2)
        })
            .then(response => response.json())
            .then(
                data => {
                    console.log(data)
                        setItems(data)
                })



    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}>
                <img src={item.imgurl} height="660px" width="100%" />
                {/*<CarouselCaption captionText={captions.heading} captionHeader={captions.altText} />*/}
            </CarouselItem>
        );
    });

    return (
        <>
            {items.length === 0 ? <h3> No images </h3> :
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />

                {slides}

                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>}

        </>);
};

export default BasicCarousel;
