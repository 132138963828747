import React, { Component,useEffect,useState } from 'react';
import BasicCarousel from './components/BasicCarousel';
import Slider from './components/Slider';


const App = () => {
	const [transData, setTransData] = useState([]);
	const [data, setData] = useState(transData);

	const getdata = async () => {
		const sendData = {
			OrgId: Number("53"),
			UserId: Number("593"),
		}


		await fetch('https://n4nairmatrimonyapi.azurewebsites.net/api/Data/Latestusers', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendData)

		})
			.then(response => response.json())
			.then(
				data => {
	

					setData(data)
					console.log(data)
		
				})


	};
	

	useEffect(() => {
		getdata()
		

	}, [])
    return (
		<>


			



			<div className="content-agileits" id="about">
				<div className="welcome-w3">
					<div className="container">
						<h3 className="w3_tittle">About Us </h3>
						<div className="welcome-grids">
							<div className="col-md-5 wel-grid1">
								<div className="port-7 effect-2">
									<div className="image-box">
										<img src="images/bg-3.jpeg" alt="Image-2" />
									</div>

								</div>
							</div>
							<div className="col-md-7 wel-grid">
								<p>
									n4nairmatrimony.com is a complete wedding portal hosted by the Nair Service Society Delhi (Regd.) with a sole objective to provide a dedicated website for the Nair community to find their partners.

									Nairs who value tradition and customs are passionate about their roots.  n4nairmatrimony.com will help them to connect with a partner from their community irrespective of whether they are from the same city or across any part of the world.   The revenue generated through this site shall only be used for charitable, humanitarian, cultural or educational activities by the parent organization, which is registered under the Societies Registration Act of 1860.

									What is more, since its inception in 2010, n4nairmatrimony.com  is  providing the best quality of matrimonial services by expanding the opportunities available to meet the potential life partners and the services have already reached a level in the lives of thousands of people living across the globe.
								</p>


							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>

			<div className="agile-serv">
				<div className="col-md-6 agileits-serv">
					<div className="left-w3l">
						<h4>Ideas About Your Wedding</h4>
						<h5>Have a DJ request box on your Sangeet</h5>
						<p>iTurn your coasters into 'DJ Request chits' that guests can scribble on. Drop them in a box for the DJ to listen to</p>
						<ul>
							<li><a href="#"><i className="glyphicon glyphicon-ok"></i>Best wedding Dress Collection Of New Year</a></li>
							<li><a href="#"><i className="glyphicon glyphicon-ok"></i>The Best wedding Songs</a></li>
							<li><a href="#"><i className="glyphicon glyphicon-ok"></i>Wedding Reception Setting.</a></li>
							<li><a href="#"><i className="glyphicon glyphicon-ok"></i>Create a Magnificent Mandap.</a></li>
							<li>    </li>
						</ul>
					</div>
				</div>
				<div className="col-md-6 right-w3l">
					<img style={{ maxHeight: '10%', maxWidth: '68%' }} src="/images/flower.jpg" alt="" />
				</div>
				<div className="clearfix"> </div>
			</div>
			<hr />

			<div className="team" id="planners">
				<div className="container">
					<h3 className="w3_tittle">Featured Profiles</h3>
					<div className="team-row-agileinfo">
						{data.map((item) => <div className="col-md-3 col-sm-6 col-xs-6 team-grids">
							<div className="thumbnail team-agileits">
								<img src={"https://mountsfileserver.azurewebsites.net" + item.image} style={{ height: "200px" }} alt="" />
								<div className="w3agile-caption">
									<div className="w3l-team-text">
										<h4>{item.organizationName}</h4>
										<h6>{item.work}</h6>
									</div>
								</div>
							</div>
						</div>)}
								
							
						{/*<div className="col-md-3 col-sm-6 col-xs-6 team-grids">
							<div className="thumbnail team-agileits">
								<img src="images/17878.png" className="img-responsive" alt="" />
								<div className="w3agile-caption">
									<div className="w3l-team-text">
										<h4>Arushi	Srinath</h4>
										<h6>Startup & Innovations</h6>

									</div>

								</div>
							</div>
						</div>*/}
						{/*<div className="col-md-3 col-sm-6 col-xs-6 team-grids">
							<div className="thumbnail team-agileits">
								<img src="images/17863.png" className="img-responsive" alt="" />
								<div className="w3agile-caption">
									<div className="w3l-team-text">
										<h4>Varun Unnikrishnan</h4>
										<h6>Medical & Healthcare </h6>

									</div>

								</div>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-6 team-grids">
							<div className="thumbnail team-agileits">
								<img src="images/17886.png" className="img-responsive" alt="" />
								<div className="w3agile-caption">
									<div className="w3l-team-text">
										<h4>Sharat	Madhusudhan</h4>
										<h6>Engineering</h6>

									</div>

								</div>
							</div>
						</div>*/}
						<div className="clearfix"> </div>
					</div>
				</div>
			</div>

			<section id="pricing" className="pricing sections-bg">
				<div className="container" data-aos="fade-up">

					<div className="section-header">
						<h2 className="price">Pricing</h2>

					</div>

					<div className="row g-4 py-lg-5" data-aos="zoom-out" data-aos-delay="100">

						<div className="col-lg-4">
							<div className="pricing-item">
								<h3 className="money">Free Registration</h3>
								<div className="icon">
									<img src="images/star.gif" height="100px" />
								</div>
								<h4 className="money-1"><sup>₹</sup>0<span> /3 month</span></h4>
								<ul>
									<li><i className="bi bi-check"></i> Limited Profile access</li>
									<li><i className="bi bi-check"></i> Profile Validity upto 3 Months </li>
									<li><i className="bi bi-check"></i> Upgrade to premium or platinum plan for more and more Profile access.</li>
								</ul>
								<div className="text-center"><a href="https://n4dashboard.azurewebsites.net/auth-register" className="buy-btn">Register Now</a></div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-item">
								<h3 className="money">Premium</h3>
								<div className="icon">
									<img src="images/globe.gif" height="100px" />
								</div>

								<h4 className="money-1"><sup>₹</sup>3000<span> /6 month</span></h4>
								<ul>
									<li><i className="bi bi-check"></i> Upto 100 profile access</li>
									<li><i className="bi bi-check"></i> Profile validity 6 months from the date of registration</li>
									<li>      </li>
									<li>      </li>

								</ul>
								<div className="text-center"><a href="#" className="buy-btn">Buy Now</a></div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-item">
								<h3 className="money">Platinum</h3>
								<div className="icon">
									<img src="images/party.gif" height="100px" />
								</div>
								<h4 className="money-1"><sup>₹</sup>5000<span> /1 Year</span></h4>
								<ul>
									<li><i className="bi bi-check"></i> Unlimited Profile access</li>
									<li><i className="bi bi-check"></i> Profile validity 1 year from the date of registration.</li>
									<li>      </li>
									<li>      </li>

								</ul>
								<div className="text-center"><a href="#" className="buy-btn">Buy Now</a></div>
							</div>
						</div>

					</div>

				</div>
			</section>

			<div id="gallery" className="welcome gallery">
				<div className="container">
					<h3 className="w3_tittle">Our Gallery</h3>
					<div className="gallery-info">
						<div className="gallery-grids">
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top">

								{/*<img src="images/pic8.jpeg" className="img-responsive" alt="" />*/}

							</div>
							<div className="col-md-6 col-sm-6 col-xs-6 gallery-grids-top">
								<div className=" bottom-grids ">

									{/*	<!--<img src="images/bg-4.1.jpg" className="img-responsive" alt="" />-->*/}



								</div>
							</div>
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top">
								<div className=" bottom-grids ">

									{/*<!--<img src="images/pic8.jpeg" className="img-responsive" alt="" />-->*/}

								</div>
							</div>
							<div className="clearfix"></div>
						</div>
						<div className="gallery-grids glry-grid-middle">
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top">

								{/*<!--<img src="images/pic9.1.jpg" className="img-responsive" alt="" />-->*/}

							</div>
							<div className="col-md-6 col-sm-6 col-xs-6 gallery-grids-top">
								<div className=" bottom-grids ">


									<img src="images/bg-1.jpeg" className="img-responsive" alt="" />

								</div>
							</div>
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top">
								<div className="bottom-grids w3ls-btm">

									{/*<!--<img src="images/pic10.1.jpg" className="img-responsive" alt="" />-->*/}

								</div>
								<div className="bottom-grids last-w3">

									{/*<!--<img src="images/pic11.1.png" className="img-responsive" alt="" />-->*/}

								</div>
							</div>
							<div className="clearfix"></div>
						</div>
						<div className="gallery-grids">
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top ga1">

								{/*<!--<img src="images/pic12.1.jpg" className="img-responsive" alt="" />-->*/}

							</div>
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top ga2">
								<div className=" bottom-grids ">

									{/*<!--<img src="images/pic12.2.png" className="img-responsive" alt="" />-->*/}

								</div>
							</div>
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top ga3">
								<div className=" bottom-grids ">


									{/*<!--<img src="images/bg-5.jpg" className="img-responsive" alt="" />-->*/}


								</div>
							</div>
							<div className="col-md-3 col-sm-3 col-xs-3 gallery-grids-top ga3">
								<div className=" bottom-grids ">

									{/*<!--<img src="images/pic10.jpeg" className="img-responsive" alt="" />-->*/}

								</div>
							</div>
							<div className="clearfix"></div>
						</div>

						<div className="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" aria-hidden="true">
							<div className="modal-content g gal">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg1.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal port3 modal fade" id="portfolioModal2" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg2.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg7.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal port2 fade" id="portfolioModal4" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg4.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg5.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg8.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal7" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg9.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal8" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg6.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal modal fade" id="portfolioModal9" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg3.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="portfolio-modal port3 modal fade" id="portfolioModal10" tabindex="-1" aria-hidden="true">
							<div className="modal-content g">
								<div className="close-modal" data-dismiss="modal">
									<div className="lr">
										<div className="rl"></div>
									</div>
								</div>
								<div className="portfolio-container">
									<div className="row">
										<div className="col-lg-8 col-lg-offset-2">
											<div className="modal-body">
												<h3>Our Gallery</h3>
												<hr />
												<img src="images/gg10.jpg" className="img-responsive img-centered" alt="" />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<hr />

			
        </>
        )
}
export default App;